import React, { useState, useCallback } from "react";
import styled from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";

import Button from "./shared/button";
import InputField from "./shared/input-field";
import media from "styled-media-query";

const MailSubtitle = styled.p`
  font-size: 1.5rem;
  line-height: 1.2;
  color: ${props => props.theme.main};
`;

const StyledForm = styled.form`
  text-align: center;
  margin: 0 auto;
  width: 50%;
  display: grid;
  grid-template-rows: minmax(0, 1.5fr) minmax(0, 0.5fr);
  grid-template-columns: minmax(0, 1.3fr) minmax(0, 0.7fr);

  > * {
    font-size: 1.4rem;
    height: 2.5em;
  }

  ${media.lessThan("medium")`
    width: auto;
  `}
`;

const StyledFormMessage = styled.p`
  grid-column: 1 / span 2;
  margin: 0.3em 0;
  text-align: left;
  width: 80%;
  height: auto;
  font-size: 1em;
  line-height: 1.2em;
  color: white;

  a {
    color: white;
  }
`;

const MailingListForm = () => {
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState();

  const handleSubmit = useCallback(
    async (e, email) => {
      e.preventDefault();

      setLoading(true);
      const { msg } = await addToMailchimp(email);
      setLoading(false);

      setRes(msg);
    },
    [setRes, setLoading]
  );

  return (
    <>
      <MailSubtitle>
        Join our mailing list to be notified of recent developments!
      </MailSubtitle>
      <StyledForm onSubmit={e => handleSubmit(e, email)}>
        <InputField
          type="email"
          onChange={e => setEmail(e.target.value)}
          placeholder="Your email here!"
        ></InputField>
        <Button as="button" type="submit">
          Join
        </Button>
        <StyledFormMessage dangerouslySetInnerHTML={{ __html: res }} />
      </StyledForm>
    </>
  );
};

export default MailingListForm;
