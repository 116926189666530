import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledButton = styled.button`
  appearance: button;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  padding: 0.5em 1.5em;
  margin: 0 0.5em;

  border: 2px solid;
  border-image-source: linear-gradient(90deg, #5f5f5f, #bfbfbf);
  border-image-slice: 1;
  background: transparent;

  text-transform: uppercase;
  color: ${props => props.theme.main};
  font-size: 1.4em;
  font-weight: bold;
  letter-spacing: 0.25em;

  :hover {
    border: 2px solid ${props => props.theme.main};
    background: ${props => props.theme.main};
    color: ${props => props.theme.background};
  }
`;

const HighlightedButton = styled(StyledButton)`
  background: ${props => props.theme.main};
  border: 2px solid ${props => props.theme.main};
  color: ${props => props.theme.background};

  :hover {
    background: ${props => props.theme.highlight};
    border: 2px solid ${props => props.theme.highlight};
    color: ${props => props.theme.main};
  }
`;

const Button = ({ highlighted, children, className, ...props }) => {
  const Component = highlighted ? HighlightedButton : StyledButton;

  return (
    <Component className={className} highlighted={highlighted} {...props}>
      {children}
    </Component>
  );
};

export default Button;

Button.propTypes = {
  highlighted: PropTypes.bool,
  children: PropTypes.node,
};
