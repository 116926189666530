import styled from "styled-components";
import media from "styled-media-query";

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${media.lessThan("medium")`
    flex-direction: column;

    > * {
      margin-bottom: 0.2em;
    }
  `}
`;

export default ButtonRow;
