import React from "react";
import styled from "styled-components";
import SectionWrapper from "./shared/section-wrapper";
import SectionContent from "./shared/section-content";

const ContactContent = styled(SectionContent)`
  margin-top: 0em;
  color: ${props => props.theme.main};
`;

const Contact = () => {
  return (
    <SectionWrapper>
      <ContactContent></ContactContent>
    </SectionWrapper>
  );
};

export default Contact;
