import React from "react";

import Layout from "../components/shared/layout";
import SEO from "../components/shared/seo";
import Hero from "../components/hero";
import Info from "../components/info";
import TlcGallery from "../components/tlc-gallery";
import Contact from "../components/contact";
import Trailer from "../components/trailer";
import SomeContactRow from "../components/some-contact-row";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Contact />
    <Trailer />
    <Info />
    <TlcGallery />
    <SomeContactRow />
  </Layout>
);

export default IndexPage;
