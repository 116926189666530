import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";

const BackgroundImg = styled(BackgroundImage)`
  width: 100%;
  background-position: ${props => props.imagePosition ?? "center"};
  background-repeat: repeat-x;
  background-size: cover;
`;

export default BackgroundImg;
