import styled from "styled-components";

const GradientTitle = styled.h1`
  background-image: linear-gradient(to bottom, #f5f5f5 20%, 60%, #8b8d90 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export default GradientTitle;
