import React from "react";
import styled from "styled-components";
import IconRow from "./shared/icon-row";
import IconButton from "./shared/icon-button";
import {
  FaSteam,
  FaPlaystation,
  FaXbox,
  FaNintendoSwitch,
} from "react-icons/fa";

const StorePageIconRow = styled(IconRow)`
  margin-top: 2rem;
  margin-bottom: 4rem;
  font-size: 1em;

  @media (max-width: 768px) {
    flex-direction: column;

    div:first-child {
      margin-bottom: 2em;
    }
  }
`;

const StorePageIconPair = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  a {
    margin: 0 20px;
  }
`;

const StorePageRow = () => {
  return (
    <StorePageIconRow>
      <StorePageIconPair>
        <IconButton href="https://store.playstation.com/en-fi/concept/10003897">
          <FaPlaystation size="3em" />
        </IconButton>
        <IconButton href="https://store.steampowered.com/app/903630/The_Last_Cube/">
          <FaSteam size="3em" />
        </IconButton>
      </StorePageIconPair>
      <StorePageIconPair>
        <IconButton href="https://www.nintendo.com/store/products/the-last-cube-switch/">
          <FaNintendoSwitch size="3em" />
        </IconButton>
        <IconButton href="https://www.xbox.com/en-us/games/store/the-last-cube/9n9rjnjcbgl7">
          <FaXbox size="3em" />
        </IconButton>
      </StorePageIconPair>
    </StorePageIconRow>
  );
};

export default StorePageRow;
