import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledInput = styled.input`
  background: white;
  height: 3em;
  padding: 0 0.6em;
`;

const InputField = ({ children, ...props }) => {
  return <StyledInput {...props}>{children}</StyledInput>;
};

export default InputField;

InputField.propTypes = {
  children: PropTypes.node,
};
