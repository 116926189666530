import React from "react";
import styled from "styled-components";

const StyledIcon = styled.a`
  padding: 20px;
  background-image: linear-gradient(to bottom, #f5f5f5 20%, 60%, #8b8d90 80%);
  color: ${props => props.theme.background};

  :hover {
    color: ${props => props.theme.main};
    border-color: ${props => props.theme.highlight};
    background-color: ${props => props.theme.highlight};
    background-image: none;
  }
`;

const IconButton = ({ className, children, ...props }) => {
  return (
    <StyledIcon
      as="a"
      className={className}
      target="_blank"
      rel="noopener"
      {...props}
    >
      {children}
    </StyledIcon>
  );
};

export default IconButton;
