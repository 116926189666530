import styled from "styled-components";

const Divider = styled.hr`
  border-top: 1px solid ${props => props.theme.main};
  display: block;
  width: ${props => props.width ?? "30%"};
  margin: ${props => props.spacing} 0;
`;

export default Divider;
