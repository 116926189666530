import React from "react";
import styled from "styled-components";
import SectionWrapper from "./shared/section-wrapper";
import SectionContent from "./shared/section-content";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImg from "./shared/background-img";
import Divider from "./shared/divider";
import StorePageRow from "./store-page-row";
import GradientTitle from "./shared/gradient-title";
import {
  FaPlaystation,
  FaSteam,
  FaNintendoSwitch,
  FaXbox,
} from "react-icons/fa";

const InfoWrapper = styled(SectionWrapper)`
  background: ${props => props.background};
`;

const InfoContent = styled(SectionContent)`
  flex-direction: column;
  align-items: center;
  color: ${props => props.theme.main};
  padding-top: 5rem;
  padding-bottom: 7rem;
  width: 50%;
  text-align: center;
  line-height: 1.5;

  > h1 {
    font-size: 2.7rem;
  }

  > p {
    font-size: 1.5rem;
  }
`;

const InfoTitle = styled(GradientTitle)`
  margin: 0;
`;

const Info = () => {
  const data = useStaticQuery(InfoQuery);

  return (
    <BackgroundImg fluid={data.file.childImageSharp.fluid}>
      <InfoWrapper as="div" background={data.file.childImageSharp.fluid}>
        <InfoContent>
          <InfoTitle>{data.site.siteMetadata.product}</InfoTitle>
          <Divider width="30%" spacing="3em" />
          <p>
            Venture through six unique areas of the cube-world and overcome
            three-dimensional brain-teasers in this novel puzzle adventure game.
          </p>
          <StorePageRow />
        </InfoContent>
      </InfoWrapper>
    </BackgroundImg>
  );
};

export default Info;

const InfoQuery = graphql`
  query {
    site {
      siteMetadata {
        product
      }
    }
    file(relativePath: { eq: "space-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
