import React from "react";
import Gallery from "./gallery";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby-plugin-modal-routing";
import Img from "gatsby-image";

const TlcGallery = () => {
  const data = useStaticQuery(graphql`
    query {
      screenshots: allFile(
        filter: { relativeDirectory: { eq: "screenshots" } }
        limit: 9
        sort: { fields: relativePath }
      ) {
        nodes {
          relativePath
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `);

  const screenshots = data.screenshots.nodes.map((x, i) => (
    <Link
      to={"/" + x.relativePath}
      key={x.childImageSharp.id}
      asModal
      alt={`Screenshot ${i}`}
    >
      <Img fluid={x.childImageSharp.fluid} />
    </Link>
  ));

  return <Gallery>{screenshots}</Gallery>;
};

export default TlcGallery;
