import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { useInView } from "react-intersection-observer";
import { FaDiscord } from "react-icons/fa";
import SectionWrapper from "./shared/section-wrapper";
import SectionContent from "./shared/section-content";
import BackgroundImg from "./shared/background-img";
import Header from "./header";
import LinkButton from "./shared/link-button";
import GradientTitle from "./shared/gradient-title";
import StorePageRow from "./store-page-row";

const HeroContent = styled(SectionContent)`
  flex-direction: column;
  align-items: center;
`;

const HeroLogo = styled(Img)`
  margin-top: 9em;
  margin-bottom: 5em;
  max-width: 100%;
`;

const HeroButton = styled(LinkButton)`
  height: 3em;
  margin-bottom: 0.5em;
  border: none;
  background-image: linear-gradient(to bottom, #f5f5f5 20%, 60%, #8b8d90 80%);

  svg {
    margin-right: 1rem;
  }
`;

const AvailableOnTitle = styled(GradientTitle)`
  margin-bottom: 10px;
`;

const Hero = () => {
  const data = useStaticQuery(HeroQuery);

  const [ref, inView, entry] = useInView({
    threshold: 0,
  });

  return (
    <>
      <Header filled={entry && !inView} />
      <BackgroundImg
        fluid={data.bg.childImageSharp.fluid}
        style={{ height: "1050px" }}
      >
        <SectionWrapper>
          <HeroContent>
            <div ref={ref}></div>
            <HeroLogo
              fixed={data.logo.childImageSharp.fixed}
              loading="eager"
              fadeIn={false}
              alt={`${data.site.siteMetadata.product} logo`}
            />
            <AvailableOnTitle>Available On</AvailableOnTitle>
            <StorePageRow />
            <HeroButton href="https://discord.gg/cNFt8Mb" highlighted>
              <FaDiscord size="1.5em" /> Join the community
            </HeroButton>
          </HeroContent>
        </SectionWrapper>
      </BackgroundImg>
    </>
  );
};

export default Hero;

const HeroQuery = graphql`
  query {
    site {
      siteMetadata {
        product
      }
    }
    logo: file(relativePath: { eq: "tlc-logo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bg: file(relativePath: { eq: "hero-bg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
