import React from "react";
import styled from "styled-components";
import IconRow from "./shared/icon-row";
import SectionWrapper from "./shared/section-wrapper";
import SectionContent from "./shared/section-content";
import MailingListForm from "./mailing-list-form";

import {
  FaTwitterSquare,
  FaFacebookSquare,
  FaInstagram,
  FaYoutube,
  FaReddit,
  FaDiscord,
} from "react-icons/fa";

const SomeContactContent = styled(SectionContent)`
  margin-top: 5em;
  margin-bottom: 3em;
  color: ${props => props.theme.main};
`;

const ContactIconRow = styled(IconRow)`
  margin-top: 2rem;
  margin-bottom: 4rem;
  font-size: 3rem;
`;

const IconLink = styled.a`
  color: inherit;

  :hover {
    color: ${props => props.theme.highlight};
  }
`;

const SomeContactRow = () => {
  return (
    <SectionWrapper>
      <SomeContactContent>
        <ContactIconRow gap="2%">
          <IconLink href="https://www.twitter.com/improxgames" alt="Twitter">
            <FaTwitterSquare />
          </IconLink>
          <IconLink href="https://www.facebook.com/improxgames" alt="Facebook">
            <FaFacebookSquare />
          </IconLink>
          <IconLink
            href="https://www.instagram.com/improxgames"
            alt="Instagram"
          >
            <FaInstagram />
          </IconLink>
          <IconLink
            href="https://www.youtube.com/channel/UC13OIIrGYFvNNrUuLiYAV2g"
            alt="Youtube"
          >
            <FaYoutube />
          </IconLink>
          <IconLink href="https://www.reddit.com/r/thelastcube" alt="Reddit">
            <FaReddit />
          </IconLink>
          <IconLink href="https://discord.gg/cNFt8Mb" alt="Discord">
            <FaDiscord />
          </IconLink>
        </ContactIconRow>
        <MailingListForm />
      </SomeContactContent>
    </SectionWrapper>
  );
};

export default SomeContactRow;
