import React from "react";
import styled from "styled-components";
import { FaDiscord } from "react-icons/fa";
import SectionWrapper from "./shared/section-wrapper";
import SectionContent from "./shared/section-content";
import ButtonRow from "./shared/button-row";
import LinkButton from "./shared/link-button";

const TrailerContent = styled(SectionContent)`
  margin-bottom: 12em;
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  width: 100%;
`;

const StyledVideo = styled.iframe`
  border: 0;
  margin-top: 6rem;
  margin-bottom: 5rem;

  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Trailer = () => {
  return (
    <SectionWrapper>
      <TrailerContent id="trailer">
        <VideoWrapper>
          <StyledVideo
            title="tlc-trailer"
            src="https://www.youtube.com/embed/ldkEU6NMP4M"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </VideoWrapper>
      </TrailerContent>
    </SectionWrapper>
  );
};

export default Trailer;
