import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SectionWrapper from "./shared/section-wrapper";
import SectionContent from "./shared/section-content";

const GalleryWrapper = styled(SectionWrapper)`
  background-color: ${props => props.theme.background};
`;

const GalleryContent = styled(SectionContent)`
  margin-top: -3rem;
  color: white;
`;

const GalleryGrid = styled.div`
  display: grid;
  width: 100%;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
`;

const Gallery = ({ children }) => {
  return (
    <GalleryWrapper>
      <GalleryContent>
        <GalleryGrid>{children}</GalleryGrid>
      </GalleryContent>
    </GalleryWrapper>
  );
};

Gallery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Gallery;
