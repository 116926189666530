import styled from "styled-components";
import PropTypes from "prop-types";

const IconRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  width: 100%;

  & * {
    margin: 0 ${props => props.gap};
  }
`;

export default IconRow;

IconRow.propTypes = {
  gap: PropTypes.string,
};

IconRow.defaultProps = {
  gap: 0,
};
